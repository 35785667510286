import { default as apiPagejgBpPHebiQMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/api/apiPage.vue?macro=true";
import { default as paramsPagefYN8U1LBzaMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/api/paramsPage.vue?macro=true";
import { default as cny_45do_45list_45store2Y5mCH9O62Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-list/cny-do-list-store.js?macro=true";
import { default as pagemQvzRc6aQEMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-list/page.vue?macro=true";
import { default as cny_45do_45patch_45create_45storefv669mzHPPMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-patch-list/cny-do-patch-create-store.js?macro=true";
import { default as cny_45do_45patch_45list_45store7JfL1oRmeXMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-patch-list/cny-do-patch-list-store.js?macro=true";
import { default as create_45modalWgFMWjliDIMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-patch-list/create-modal.vue?macro=true";
import { default as pageDeQXhKsDjXMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-patch-list/page.vue?macro=true";
import { default as pagegM7SPtC9efMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/usdt-do-list/page.vue?macro=true";
import { default as usdt_45do_45list_45stored8Nfc6OJDeMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/usdt-do-list/usdt-do-list-store.js?macro=true";
import { default as home_45storel5A0MQnO5pMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/home/home-store.js?macro=true";
import { default as homeStatisticPayMethodTableRJzTUaEN3eMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/home/homeStatisticPayMethodTable.vue?macro=true";
import { default as pageWfAWakyoFFMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/home/page.vue?macro=true";
import { default as mobileUKCaVT2xANMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/im/mobile.vue?macro=true";
import { default as pageLv5MOFP7ssMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/im/page.vue?macro=true";
import { default as pcrNlTl2L1vJMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/im/pc.vue?macro=true";
import { default as indexqFPI4qnTDrMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/index.vue?macro=true";
import { default as login_45store3w0GG2319hMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/login/login-store.js?macro=true";
import { default as pageLu0hYLkcb5Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/login/page.vue?macro=true";
import { default as merch_45detail_45storeFSlxeHgGUqMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/merch-detail/merch-detail-store.js?macro=true";
import { default as page5xwX83q8n4Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/merch-detail/page.vue?macro=true";
import { default as cny_45pb_45list_45storenkQf4vTzUFMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/cny-pb-list/cny-pb-list-store.js?macro=true";
import { default as createPbModalc0ZihQWWefMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/cny-pb-list/createPbModal.vue?macro=true";
import { default as pageYdWbzgUBhXMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/cny-pb-list/page.vue?macro=true";
import { default as paymentVoucherModal7lMAiEIHYSMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/cny-pb-list/paymentVoucherModal.vue?macro=true";
import { default as createPbModalgXjckHuHGgMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/usdt-pb-list/createPbModal.vue?macro=true";
import { default as pagejs3KCnyjlRMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/usdt-pb-list/page.vue?macro=true";
import { default as usdt_45pb_45list_45store7puNXtterGMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/usdt-pb-list/usdt-pb-list-store.js?macro=true";
import { default as pagepekbde9RdgMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/reset-password/page.vue?macro=true";
import { default as page7Q9i4c7yteMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/settings/page.vue?macro=true";
import { default as settings_45storeVkA85XW85CMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/settings/settings-store.js?macro=true";
import { default as detailN9F4Y5BHD3Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/station-letter/detail.vue?macro=true";
import { default as pagex0LYfPX6j9Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/station-letter/page.vue?macro=true";
import { default as station_45letter_45detail_45storewpBV21hM1RMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/station-letter/station-letter-detail-store.js?macro=true";
import { default as station_45letter_45storezRiCJzgQZBMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/station-letter/station-letter-store.js?macro=true";
import { default as page1ITilS5wIdMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/user-center/page.vue?macro=true";
import { default as user_45center_45storez5ZNomPxmfMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/user-center/user-center-store.js?macro=true";
import { default as cny_45bill_45store4pwy7FBskkMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/bill/cny-bill-store.js?macro=true";
import { default as pageI54kQUQs9KMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/bill/page.vue?macro=true";
import { default as merch_45wallet_45cny_45storeevyyOWZDhsMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/merch-wallet-cny-store.js?macro=true";
import { default as pagevuuJVc96L8Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/page.vue?macro=true";
import { default as trans_45cny_45to_45usdt_45modal_45storeMwNRBCWBWHMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/trans-cny-to-usdt-modal-store.js?macro=true";
import { default as trans_45cny_45to_45usdt_45modalnpslIuYQxXMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/trans-cny-to-usdt-modal.vue?macro=true";
import { default as pagevwbNNyXEPHMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/bill/page.vue?macro=true";
import { default as usdt_45bill_45storezQTgnlf9f2Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/bill/usdt-bill-store.js?macro=true";
import { default as merch_45wallet_45usdt_45storeuVlgn9Y1N9Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/merch-wallet-usdt-store.js?macro=true";
import { default as pagesQAM8rqfAIMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/page.vue?macro=true";
import { default as trans_45usdt_45to_45cny_45modal_45storeRAdatvkVnXMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/trans-usdt-to-cny-modal-store.js?macro=true";
import { default as trans_45usdt_45to_45cny_45modalJRqhm0ImfrMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/trans-usdt-to-cny-modal.vue?macro=true";
export default [
  {
    name: "api-apiPage",
    path: "/api/apiPage",
    meta: apiPagejgBpPHebiQMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/api/apiPage.vue").then(m => m.default || m)
  },
  {
    name: "api-paramsPage",
    path: "/api/paramsPage",
    meta: paramsPagefYN8U1LBzaMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/api/paramsPage.vue").then(m => m.default || m)
  },
  {
    name: "do-cny-do-list-cny-do-list-store",
    path: "/do/cny-do-list/cny-do-list-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-list/cny-do-list-store.js").then(m => m.default || m)
  },
  {
    name: "do-cny-do-list-page",
    path: "/do/cny-do-list/page",
    meta: pagemQvzRc6aQEMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-list/page.vue").then(m => m.default || m)
  },
  {
    name: "do-cny-do-patch-list-cny-do-patch-create-store",
    path: "/do/cny-do-patch-list/cny-do-patch-create-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-patch-list/cny-do-patch-create-store.js").then(m => m.default || m)
  },
  {
    name: "do-cny-do-patch-list-cny-do-patch-list-store",
    path: "/do/cny-do-patch-list/cny-do-patch-list-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-patch-list/cny-do-patch-list-store.js").then(m => m.default || m)
  },
  {
    name: "do-cny-do-patch-list-create-modal",
    path: "/do/cny-do-patch-list/create-modal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-patch-list/create-modal.vue").then(m => m.default || m)
  },
  {
    name: "do-cny-do-patch-list-page",
    path: "/do/cny-do-patch-list/page",
    meta: pageDeQXhKsDjXMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/cny-do-patch-list/page.vue").then(m => m.default || m)
  },
  {
    name: "do-usdt-do-list-page",
    path: "/do/usdt-do-list/page",
    meta: pagegM7SPtC9efMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/usdt-do-list/page.vue").then(m => m.default || m)
  },
  {
    name: "do-usdt-do-list-usdt-do-list-store",
    path: "/do/usdt-do-list/usdt-do-list-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/do/usdt-do-list/usdt-do-list-store.js").then(m => m.default || m)
  },
  {
    name: "home-home-store",
    path: "/home/home-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/home/home-store.js").then(m => m.default || m)
  },
  {
    name: "home-homeStatisticPayMethodTable",
    path: "/home/homeStatisticPayMethodTable",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/home/homeStatisticPayMethodTable.vue").then(m => m.default || m)
  },
  {
    name: "home-page",
    path: "/home/page",
    meta: pageWfAWakyoFFMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/home/page.vue").then(m => m.default || m)
  },
  {
    name: "im-mobile",
    path: "/im/mobile",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/im/mobile.vue").then(m => m.default || m)
  },
  {
    name: "im-page",
    path: "/im/page",
    meta: pageLv5MOFP7ssMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/im/page.vue").then(m => m.default || m)
  },
  {
    name: "im-pc",
    path: "/im/pc",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/im/pc.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexqFPI4qnTDrMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login-login-store",
    path: "/login/login-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/login/login-store.js").then(m => m.default || m)
  },
  {
    name: "login-page",
    path: "/login/page",
    meta: pageLu0hYLkcb5Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/login/page.vue").then(m => m.default || m)
  },
  {
    name: "merch-detail-merch-detail-store",
    path: "/merch-detail/merch-detail-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/merch-detail/merch-detail-store.js").then(m => m.default || m)
  },
  {
    name: "merch-detail-page",
    path: "/merch-detail/page",
    meta: page5xwX83q8n4Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/merch-detail/page.vue").then(m => m.default || m)
  },
  {
    name: "pb-cny-pb-list-cny-pb-list-store",
    path: "/pb/cny-pb-list/cny-pb-list-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/cny-pb-list/cny-pb-list-store.js").then(m => m.default || m)
  },
  {
    name: "pb-cny-pb-list-createPbModal",
    path: "/pb/cny-pb-list/createPbModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/cny-pb-list/createPbModal.vue").then(m => m.default || m)
  },
  {
    name: "pb-cny-pb-list-page",
    path: "/pb/cny-pb-list/page",
    meta: pageYdWbzgUBhXMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/cny-pb-list/page.vue").then(m => m.default || m)
  },
  {
    name: "pb-cny-pb-list-paymentVoucherModal",
    path: "/pb/cny-pb-list/paymentVoucherModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/cny-pb-list/paymentVoucherModal.vue").then(m => m.default || m)
  },
  {
    name: "pb-usdt-pb-list-createPbModal",
    path: "/pb/usdt-pb-list/createPbModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/usdt-pb-list/createPbModal.vue").then(m => m.default || m)
  },
  {
    name: "pb-usdt-pb-list-page",
    path: "/pb/usdt-pb-list/page",
    meta: pagejs3KCnyjlRMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/usdt-pb-list/page.vue").then(m => m.default || m)
  },
  {
    name: "pb-usdt-pb-list-usdt-pb-list-store",
    path: "/pb/usdt-pb-list/usdt-pb-list-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/pb/usdt-pb-list/usdt-pb-list-store.js").then(m => m.default || m)
  },
  {
    name: "reset-password-page",
    path: "/reset-password/page",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/reset-password/page.vue").then(m => m.default || m)
  },
  {
    name: "settings-page",
    path: "/settings/page",
    meta: page7Q9i4c7yteMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/settings/page.vue").then(m => m.default || m)
  },
  {
    name: "settings-settings-store",
    path: "/settings/settings-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/settings/settings-store.js").then(m => m.default || m)
  },
  {
    name: "station-letter-detail",
    path: "/station-letter/detail",
    meta: detailN9F4Y5BHD3Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/station-letter/detail.vue").then(m => m.default || m)
  },
  {
    name: "station-letter-page",
    path: "/station-letter/page",
    meta: pagex0LYfPX6j9Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/station-letter/page.vue").then(m => m.default || m)
  },
  {
    name: "station-letter-station-letter-detail-store",
    path: "/station-letter/station-letter-detail-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/station-letter/station-letter-detail-store.js").then(m => m.default || m)
  },
  {
    name: "station-letter-station-letter-store",
    path: "/station-letter/station-letter-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/station-letter/station-letter-store.js").then(m => m.default || m)
  },
  {
    name: "user-center-page",
    path: "/user-center/page",
    meta: page1ITilS5wIdMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/user-center/page.vue").then(m => m.default || m)
  },
  {
    name: "user-center-user-center-store",
    path: "/user-center/user-center-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/user-center/user-center-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-cny-bill-cny-bill-store",
    path: "/wallet/cny/bill/cny-bill-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/bill/cny-bill-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-cny-bill-page",
    path: "/wallet/cny/bill/page",
    meta: pageI54kQUQs9KMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/bill/page.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cny-merch-wallet-cny-store",
    path: "/wallet/cny/merch-wallet-cny-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/merch-wallet-cny-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-cny-page",
    path: "/wallet/cny/page",
    meta: pagevuuJVc96L8Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/page.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cny-trans-cny-to-usdt-modal-store",
    path: "/wallet/cny/trans-cny-to-usdt-modal-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/trans-cny-to-usdt-modal-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-cny-trans-cny-to-usdt-modal",
    path: "/wallet/cny/trans-cny-to-usdt-modal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/cny/trans-cny-to-usdt-modal.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-bill-page",
    path: "/wallet/usdt/bill/page",
    meta: pagevwbNNyXEPHMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/bill/page.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-bill-usdt-bill-store",
    path: "/wallet/usdt/bill/usdt-bill-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/bill/usdt-bill-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-merch-wallet-usdt-store",
    path: "/wallet/usdt/merch-wallet-usdt-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/merch-wallet-usdt-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-page",
    path: "/wallet/usdt/page",
    meta: pagesQAM8rqfAIMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/page.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-trans-usdt-to-cny-modal-store",
    path: "/wallet/usdt/trans-usdt-to-cny-modal-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/trans-usdt-to-cny-modal-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-trans-usdt-to-cny-modal",
    path: "/wallet/usdt/trans-usdt-to-cny-modal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/pages/wallet/usdt/trans-usdt-to-cny-modal.vue").then(m => m.default || m)
  }
]