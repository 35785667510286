import {navigateTo} from "nuxt/app";
import {MainLayoutStore} from "~/layouts/main-layout-store";
import {UserStore} from '~/store/user-store';

export default defineNuxtRouteMiddleware((to, from) => {
    const userStore = UserStore();
    const hasLogin = userStore.hasLogin();

    //已登录时如果在登录/注册页 直接跳转到首页
    if (hasLogin) {
        if (to.path.startsWith('/login/') || to.path.startsWith('/reset-password/')) {
            return navigateTo('/home/page')
        }
        if (to.path === '/') {
            return navigateTo('/home/page')
        }
    }

    //未登录跳转到登录页
    if (!hasLogin) {
        //已经重定向过 直接返回
        if (to.path === '/login/page') {
            return true
        }
        //已经在 登录页 直接返回
        if (from.path === '/login/page') {
            return true
        }
        //已经在 忘记密码页 直接返回
        if (from.path === '/reset-password/page') {
            return true
        }
        if (from.path === '/reset-password/phone-verify-page') {
            return true
        }
        if (from.path === '/reset-password/reset-page') {
            return true
        }



        return navigateTo('/login/page')
    }

    //路由切换后关闭侧边栏
    const mainLayoutStore = MainLayoutStore();
    mainLayoutStore.sidebarOpen = false;
})
